import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setReaderUrl } from '../../store/reader/actions';
import { goBack } from 'react-router-redux';
import * as API from '../../networking/api';
import propTypes from 'prop-types';

import { Panel, PanelHeader, PanelHeaderBack, ScreenSpinner } from '@vkontakte/vkui';

import './index.scss';

const BookContent = ({ id }) => {
    const dispatch = useDispatch();
    const currentBookId = useSelector(store => store.reader.currentBook);
    const readerUrl = useSelector(store => store.reader.readerUrl);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (currentBookId) {
            setLoading(true);
            API.getReaderUrl(currentBookId).then(data => {
                if (data.url) {
                    dispatch(setReaderUrl(data.url));
                }
                setLoading(false);
            });
        }
    }, [currentBookId]);

    return (
        <Panel id={id} header={false}>
            <div className="book-content">
                <PanelHeader left={<PanelHeaderBack fill="#19191a" onClick={() => dispatch(goBack())} />}></PanelHeader>
                {readerUrl && <iframe src={`https://back.litres.juice.vk-apps.com${readerUrl}&art=${currentBookId}`} />}
                {loading && <ScreenSpinner />}
            </div>
        </Panel>
    );
};

BookContent.propTypes = {
    id: propTypes.string.isRequired,
};

BookContent.defaultProps = {
    mainPage: true,
};

export default BookContent;
