import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { store } from '@mini-core/core';
import { ModalCard, ModalPageHeader, Slider, Cell, FormLayout } from '@vkontakte/vkui';
import Icon24Add from '@vkontakte/icons/dist/24/add';
import brightnessSvg from '../../assets/brightness-28.svg';
import minus from '../../assets/minus-24.svg';
import './index.scss';

const ReaderSettings = ({ id, router }) => {
    const [settings, settingsActions] = store.useModel('reader-settings');
    const { activeTheme, activeFont, activeSize } = settings;
    const { setTheme, setFont, setSize } = settingsActions;

    const fontValueToFontName = {
        0: 'Arial',
        2: 'Verdana',
        3: 'Helvetica',
        4: 'Times New Roman',
        6: 'Georgia',
        9: 'Cambria',
    };

    useEffect(() => {
        window.frames[0].postMessage(JSON.stringify({ type: 'LitresWebAppSwitchTheme', theme: activeTheme }), '*');
    }, [activeTheme]);

    useEffect(() => {
        window.frames[0].postMessage(JSON.stringify({ type: 'LitresWebAppSwitchFontSize', size: activeSize }), '*');
    }, [activeSize]);

    return (
        <div className="reader-settings">
            <ModalCard
                id={id}
                header={<ModalPageHeader>Настройки</ModalPageHeader>}
                onClose={() => router.openModal(null)}
            >
                <FormLayout>
                    <img src={brightnessSvg} alt="" />
                    <Slider step={1} min={0} max={9} value={+activeTheme} onChange={e => setTheme(e)} />
                </FormLayout>
                <div className="colors">
                    <span className="reader-settings__title reader-settings__title--color">Цвет</span>
                    <div className={`white ${activeTheme === 9 && 'selected'}`} onClick={() => setTheme(9)}></div>
                    <div className={`pearls ${activeTheme === 4 && 'selected'}`} onClick={() => setTheme(4)}></div>
                    <div className={`black ${activeTheme === 0 && 'selected'}`} onClick={() => setTheme(0)}></div>
                </div>
                <div className="fonts">
                    <Cell
                        expandable
                        indicator={fontValueToFontName[activeFont]}
                        onClick={() => {
                            router.openModal('select-font');
                        }}
                    >
                        Шрифты
                    </Cell>
                </div>
                <div className="font-size">
                    <Cell
                        indicator={
                            <div className="font-size__controls">
                                <button
                                    className="font-size__control font-size__control--min"
                                    onClick={() => setSize(activeSize - 1 <= 0 ? 0 : activeSize - 1)}
                                >
                                    <img src={minus} alt="" />
                                </button>

                                <span className="font-size__value">{activeSize * 10}%</span>

                                <button
                                    className="font-size__control font-size__control--max"
                                    onClick={() => setSize(activeSize + 1 >= 10 ? 10 : activeSize + 1)}
                                >
                                    <Icon24Add />
                                </button>
                            </div>
                        }
                    >
                        Размер шрифта
                    </Cell>
                </div>
            </ModalCard>
        </div>
    );
};

ReaderSettings.propTypes = {
    id: propTypes.string.isRequired,
};

export default ReaderSettings;
