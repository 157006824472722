import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { Button, FixedLayout, Panel } from '@vkontakte/vkui';
import { setVKStorage } from '../../networking/api';
import litres_logo from '../../assets/litres-ru-logo-white.svg';
import bridge from '@vkontakte/vk-bridge';
import GA from 'react-ga';
import * as API from '../../networking/api';
import './index.scss';

const Login = ({ id }) => {
    const dispatch = useDispatch();
    const [isNextPage, setIsNextPage] = useState(false);

    useEffect(() => {
        GA.pageview('/login');
        (async () => {})();
    }, []);

    const onLoginBtnClickHandler = () => {
        bridge
            .send('VKWebAppGetAuthToken', { app_id: 7371328, scope: '' })
            .then(data => {
                return API.getAuthUser(data.access_token);
            })
            .then(data => {
                if (data.success) {
                    dispatch(push('shop'));
                    setVKStorage('allow_token', '1');
                }
            });
    };

    return (
        <Panel id={id} centered>
            {!isNextPage && (
                <>
                    <img className="login__logo" src={litres_logo} alt="Логотип Литрес" />
                    <div className="login__text">Покупайте и читайте любимые книги не выходя из ВКонтакте</div>
                </>
            )}
            {isNextPage && (
                <>
                    <img className="login__logo" src={litres_logo} alt="Логотип Литрес" />
                    <div className="login__text">
                        Авторизуйтесь через ВКонтакте, чтобы мы могли показать вам те книги, которые вы уже купили на
                        сайте ЛитРес
                    </div>
                </>
            )}
            <FixedLayout vertical="bottom">
                <div
                    style={{
                        margin: '0 24px',
                    }}
                >
                    {!isNextPage && (
                        <Button mode="overlay-primary" size="xl" onClick={() => setIsNextPage(true)}>
                            Продолжить
                        </Button>
                    )}
                    {isNextPage && (
                        <Button mode="overlay-primary" size="xl" onClick={onLoginBtnClickHandler}>
                            Авторизоваться через VK
                        </Button>
                    )}
                </div>
            </FixedLayout>
        </Panel>
    );
};

export default Login;
