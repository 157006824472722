import React from 'react';
import propTypes from 'prop-types';
import { Avatar, Cell, ActionSheet, ActionSheetItem, platform, IOS } from '@vkontakte/vkui';
import { useDispatch } from 'react-redux';
import { setCurrentBookReader, setReaderUrl } from '../../store/reader/actions';
import { setPopout } from '../../store/navigation/actions';
import FireEvent from '../../utils/FireEvent';
import * as API from '../../networking/api';
import './index.scss';

const BookInProgress = props => {
    const dispatch = useDispatch();
    const { id, title, cover, persons, readed_percent, type, openBook } = props;

    const onReadBtnClickHandler = async () => {
        if (Number(type) === 4) {
            try {
                const result = await API.getReaderUrl(id);
                dispatch(
                    setPopout(
                        <ActionSheet onClose={() => dispatch(setPopout(null))}>
                            {result.urls.map((head, pos) => {
                                return (
                                    <ActionSheetItem
                                        autoclose
                                        onClick={() => FireEvent(`https://back.litres.juice.vk-apps.com${head}`)}
                                    >
                                        Часть {pos + 1}
                                    </ActionSheetItem>
                                );
                            })}
                            {platform() === IOS && (
                                <ActionSheetItem autoclose mode="cancel">
                                    Отменить
                                </ActionSheetItem>
                            )}
                        </ActionSheet>,
                    ),
                );
                return;
            } catch (e) {
                throw new Error('Ошибка'); // TODO обработка ошибки
            }
        }

        dispatch(setCurrentBookReader(id));
        openBook();
    };

    return (
        <div className="book-in-progress">
            <Cell
                before={<Avatar src={`https://cv${id[id.length - 2]}.litres.ru/pub/c/cover/${id}.jpg`} mode="image" />}
                size="l"
                description={<div>{persons && persons[0].full_name}</div>}
                bottomContent={<span onClick={onReadBtnClickHandler}>Читать</span>}
            >
                {title}
            </Cell>
        </div>
    );
};

BookInProgress.propTypes = {
    cover: propTypes.string,
    author: propTypes.string,
    title: propTypes.string,
    price: propTypes.string,
    showButton: propTypes.bool,
};

export default BookInProgress;
