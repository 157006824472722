import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Panel, PanelHeader, PanelHeaderBack, Div, Group, Cell, Card } from '@vkontakte/vkui';
import orangeBookmarks from '../../assets/bookmarks-28-orange.svg';
import './index.scss';

const BookStructure = ({ id, router }) => {
    const [selectedTab, selectTab] = useState('Оглавление');

    return (
        <Panel id={id}>
            <div className="book-structure">
                <PanelHeader left={<PanelHeaderBack fill="#000" onClick={() => router.goBack()} />}>
                    Long long long
                </PanelHeader>
                <Div className="book-structure__tabs">
                    <div
                        className={
                            selectedTab === 'Оглавление' ? 'book-structure__tab selected' : 'book-structure__tab'
                        }
                        onClick={() => selectTab('Оглавление')}
                    >
                        Оглавление
                    </div>
                    <div
                        className={selectedTab === 'Закладки' ? 'book-structure__tab selected' : 'book-structure__tab'}
                        onClick={() => selectTab('Закладки')}
                    >
                        Закладки
                    </div>
                </Div>
                <Div className="book-structure__tabs-content">
                    <div
                        className={
                            selectedTab === 'Оглавление'
                                ? 'book-structure__tab-content selected'
                                : 'book-structure__tab-content'
                        }
                    >
                        <Group>
                            <Cell indicator={1}>
                                <img src={orangeBookmarks} alt="" />
                                Глава 1
                            </Cell>
                        </Group>
                        <Group>
                            <Cell indicator={1}>Глава 2</Cell>
                        </Group>
                        <Group>
                            <Cell indicator={1}>
                                <img src={orangeBookmarks} alt="" />
                                Глава 3
                            </Cell>
                        </Group>
                        <Group>
                            <Cell indicator={1}>Глава 4</Cell>
                        </Group>
                    </div>
                    <div
                        className={
                            selectedTab === 'Закладки'
                                ? 'book-structure__tab-content selected'
                                : 'book-structure__tab-content'
                        }
                    >
                        <Card className="book-structure__card">
                            <div className="book-structure__card--upper">
                                <span>Глава 1</span>
                                <span>1 стр</span>
                            </div>
                            <p className="book-structure__bottom">
                                «Высокая Республика» покажет джедаев на пике их могущества…
                            </p>
                        </Card>
                        <Card className="book-structure__card">
                            <div className="book-structure__card--upper">
                                <span>Глава3</span>
                                <span>180 стр</span>
                            </div>
                            <p className="book-structure__card--bottom">
                                «Высокая Республика» покажет джедаев на пике их могущества…
                            </p>
                        </Card>
                    </div>
                </Div>
            </div>
        </Panel>
    );
};

BookStructure.propTypes = {
    id: propTypes.string.isRequired,
};

export default BookStructure;
