import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import {
    Panel,
    PanelHeader,
    Search,
    Header,
    Link,
    CardScroll,
    Group,
    Spinner,
    ANDROID,
    Alert,
    Placeholder,
    platform,
} from '@vkontakte/vkui';
import { debounce } from 'lodash';
import { loadBooks, setShowAllTitle, setShowAllType } from '../../store/shop/actions';
import { setPopout } from '../../store/navigation/actions';
import Icon28HelpOutline from '@vkontakte/icons/dist/28/help_outline';
import SliderCell from '../../components/SliderCell';
import SearchResult from '../../components/SearchResult';
import BookCard from '../../components/BookCard';
import FireEvent from '../../utils/FireEvent';
import VkRetargetEvent from '../../utils/VkRetargetEvent';
import GA from 'react-ga';
import * as API from '../../networking/api';
import './index.scss';

const Shop = ({ id }) => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [searchCounter, setSearchCounter] = useState(10);
    const [foundBooks, loadFoundBooks] = useState([]);
    const [loading, setLoading] = useState(false);
    const books = useSelector(state => state.shop.books);
    const showAllContainerType = useSelector(state => state.shop.showAllContainerType);

    const openShowAllPanel = (containerTitle, containerType) => {
        GA.event({
            category: 'Navigation',
            action: `click-show-more`,
            label: `${containerType}`,
        });
        dispatch(setShowAllTitle(containerTitle));
        dispatch(setShowAllType(containerType));
        dispatch(push('show-all'));
    };

    useEffect(() => {
        API.getBooks().then(books => {
            dispatch(loadBooks(books));
        });
    }, []);

    useEffect(() => {
        GA.ga('send', 'pageview', '/shop/main-page');
    }, []);

    const searchHandler = useCallback(
        debounce(value => {
            API.searchBooks(value, String(searchCounter))
                .then(data => {
                    if (data.result.success) {
                        setLoading(false);
                        loadFoundBooks(data.result.arts);
                    }
                })
                .catch(() => {
                    setLoading(false);
                    dispatch(
                        setPopout(
                            <Alert
                                actions={[
                                    {
                                        title: 'Закрыть',
                                        autoclose: true,
                                        mode: 'cancel',
                                    },
                                ]}
                                onClose={() => dispatch(setPopout(null))}
                            >
                                <h2>Что-то пошло не так</h2>
                                <p>Не удалось загрузить книги</p>
                            </Alert>,
                        ),
                    );
                });
        }, 2000),
        [],
    );

    const infiniteScrollLoader = () => {
        const getDocHeight = () => {
            const D = document;
            return Math.max(
                D.body.scrollHeight,
                D.documentElement.scrollHeight,
                D.body.offsetHeight,
                D.documentElement.offsetHeight,
                D.body.clientHeight,
                D.documentElement.clientHeight,
            );
        };

        const windowHeight = window.innerHeight || (document.documentElement || document.body).clientHeight;
        const docHeight = getDocHeight();
        const trackLength = docHeight - windowHeight;
        const scrollTop =
            window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

        if (trackLength - 150 <= scrollTop) {
            setLoading(true);
            API.searchBooks(searchValue, String(searchCounter + 10))
                .then(data => {
                    if (data.result.success) {
                        setLoading(false);

                        if (data.result.arts.length === foundBooks.length) {
                            window.removeEventListener('scroll', infiniteScrollLoader);
                            return;
                        }
                        setSearchCounter(searchCounter + 10);
                        loadFoundBooks(data.result.arts);
                    }
                })
                .catch(() => {
                    setLoading(false);
                    dispatch(
                        setPopout(
                            <Alert
                                actions={[
                                    {
                                        title: 'Закрыть',
                                        autoclose: true,
                                        mode: 'cancel',
                                    },
                                ]}
                                onClose={() => dispatch(setPopout(null))}
                            >
                                <h2>Что-то пошло не так</h2>
                                <p>Не удалось загрузить книги</p>
                            </Alert>,
                        ),
                    );
                });
        }
    };

    useEffect(() => {
        if (searchValue) {
            window.addEventListener('scroll', infiniteScrollLoader);
        } else {
            window.removeEventListener('scroll', infiniteScrollLoader);
        }
        return () => window.removeEventListener('scroll', infiniteScrollLoader);
    }, [searchCounter, searchValue]);

    const trackButBtnHandler = () => {
        GA.event({
            category: 'User',
            action: 'click-buy-btn',
            label: 'shop/main-page',
        });

        VkRetargetEvent('click_pay');
    };

    const handleSupport = () => {
        FireEvent('https://vk.com/support?act=home_litres');
    };

    return (
        <Panel id={id}>
            <div className="shop">
                <PanelHeader
                    separator={false}
                    left={<Icon28HelpOutline fill="var(--button_primary_background)" onClick={handleSupport} />}
                >
                    <svg width="89px" height="20px" viewBox="0 0 89 20" version="1.1">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="litres/mobile/3" transform="translate(-143.000000, -60.000000)" fill-rule="nonzero">
                                <g id="litres-ru-logo" transform="translate(143.000000, 60.000000)">
                                    <path
                                        d="M7.64469726,3.66993348 C7.57433916,5.34102137 7.49498795,6.862262 7.40717258,8.23471442 C7.31882821,9.60663732 7.20033042,10.8430094 7.05167911,11.9417126 C6.90196979,13.0409453 6.70412074,14.0130991 6.45813189,14.8571149 C6.21214311,15.7016603 5.89579621,16.4313051 5.50909121,17.0471084 C5.26310243,17.4521725 4.98590214,17.7862842 4.67854838,18.0499729 C4.37119463,18.3131322 4.05008666,18.5244009 3.71681151,18.6832496 C3.38247833,18.8415687 3.05290623,18.9511743 2.72809521,19.0131253 C2.40275517,19.0745468 2.09963348,19.1052575 1.81873014,19.1052575 C1.41456788,19.1052575 1.07600265,19.0745468 0.803563432,19.0131253 C0.531124216,18.9511743 0.263975084,18.8590421 0,18.7356696 L0.395169114,15.6227655 C0.500441742,15.6582417 0.605714368,15.6841869 0.711516005,15.7016603 C0.816788637,15.7191336 0.922590274,15.7281351 1.0278629,15.7281351 C1.30876625,15.7281351 1.58596654,15.6582417 1.85787674,15.5163368 C2.13031596,15.3765501 2.36307956,15.1388065 2.55722556,14.8041654 C2.82014263,14.4181632 3.04867417,13.8510734 3.24229116,13.1028963 C3.43537915,12.3552486 3.6067778,11.4010976 3.75595811,10.2399139 C3.90513842,9.07873026 4.02416526,7.69409947 4.11198062,6.08443305 C4.19926697,4.47476668 4.26063192,2.59770505 4.29660447,0.451659683 L15.1835929,0.451659683 L15.1835929,18.9204636 L11.5720541,18.9204636 L11.5720541,3.66993348 L7.64469726,3.66993348 Z"
                                        id="Fill-1"
                                        fill="var(--text_primary)"
                                    ></path>
                                    <polygon
                                        id="Fill-2"
                                        fill="var(--text_primary)"
                                        points="26.0055133 11.0315098 25.9266911 11.0315098 24.7930266 12.957285 20.100724 18.9204636 17.9391965 18.9204636 17.9391965 5.72808258 21.3661116 5.72808258 21.3661116 12.00737 21.2079381 13.8018304 21.3132107 13.8018304 22.3945035 11.9284752 27.1391779 5.72808258 29.3007053 5.72808258 29.3007053 18.9204636 25.8743193 18.9204636 25.8743193 12.9043354"
                                    ></polygon>
                                    <polygon
                                        id="Fill-3"
                                        fill="var(--text_primary)"
                                        points="38.6038433 8.76209195 38.6038433 18.9199341 35.0716556 18.9199341 35.0716556 8.76209195 31.3024723 8.76209195 31.3024723 5.72755311 42.2682829 5.72755311 42.2682829 8.76209195"
                                    ></polygon>
                                    <path
                                        d="M49.8949939,3.30034561 C49.5437325,3.30034561 49.2093993,3.31358299 48.8935814,3.34005777 C48.5772345,3.36600305 48.3222526,3.39724329 48.1291646,3.43271949 L48.1291646,9.42184337 C48.1989937,9.43931674 48.3042663,9.45731958 48.4455115,9.47426342 C48.5856987,9.49226626 48.735937,9.50550368 48.8935814,9.51397558 C49.0512259,9.522977 49.2051673,9.527213 49.3548766,9.527213 L49.6839197,9.527213 C50.158969,9.527213 50.6292573,9.48379437 51.0942555,9.39536858 C51.5603117,9.30800184 51.9776992,9.14491721 52.3469468,8.90770321 C52.7161947,8.66995974 53.0097942,8.340084 53.2298616,7.91754658 C53.4488711,7.49553863 53.5594337,6.94168632 53.5594337,6.25598963 C53.5594337,5.67513305 53.4541611,5.19593958 53.2430868,4.81735028 C53.0325416,4.43981997 52.7548121,4.13588954 52.4125439,3.90767697 C52.0702756,3.67893491 51.6782805,3.52008625 51.2397327,3.43271949 C50.8006559,3.34429374 50.351528,3.30034561 49.8949939,3.30034561 M46.9563535,0.306048413 C47.8429712,0.226624085 48.7264148,0.186911921 49.6050974,0.186911921 C50.5186946,0.186911921 51.4375818,0.262100285 52.3596431,0.411418023 C53.2822332,0.560735763 54.1133053,0.859900732 54.8507426,1.30838344 C55.5892379,1.75686615 56.1907205,2.38114137 56.6562474,3.18120912 C57.1212458,3.98233584 57.3545384,5.02438304 57.3545384,6.30788021 C57.3545384,7.46906389 57.1535153,8.45021911 56.7482947,9.25028684 C56.3441326,10.0508841 55.8087763,10.7053405 55.1406389,11.2157742 C54.4725016,11.7262079 53.7128458,12.0957958 52.8611426,12.3240083 C52.0083816,12.5532799 51.1249379,12.6671214 50.2123988,12.6671214 L49.7891923,12.6671214 C49.5955753,12.6671214 49.3945522,12.662356 49.1829489,12.6538841 C48.9724036,12.6448826 48.7655615,12.6321747 48.5634803,12.6147014 C48.3613992,12.5972281 48.2159219,12.5792252 48.1286356,12.5617518 L48.1286356,18.9204636 L44.5176257,18.9204636 L44.5176257,0.635394632 C45.2550632,0.495078316 46.0686777,0.384943246 46.9563535,0.306048413 Z"
                                        id="Fill-4"
                                        fill="#FF4C00"
                                    ></path>
                                    <path
                                        d="M64.2289995,8.048332 C63.1577579,8.07957221 62.6573163,8.34961495 62.2097753,8.87593347 C61.7654084,9.39960458 61.5088395,10.1144235 61.4226111,11.0113889 L66.3445032,11.0574551 C66.4143326,10.1080696 66.3291621,9.37471826 65.9715526,8.86481405 C65.6134142,8.35438037 64.9981774,8.02556368 64.2289995,8.048332 M66.9634432,18.8542766 C66.0593679,19.1449697 65.0965732,19.2900515 64.0771742,19.2900515 C61.9505616,19.2900515 60.3958063,18.6700122 59.4113221,17.4294042 C58.4273668,16.1893257 57.9359184,14.4875271 57.9359184,12.3240083 C57.9359184,10.0026999 58.4892611,8.26065968 59.5959458,7.10000547 C60.70316,5.93882179 62.2584442,5.35849474 64.2617984,5.35849474 C64.9294063,5.35849474 65.5800868,5.44639095 66.2127805,5.62218347 C66.8454742,5.797976 67.4072811,6.08866905 67.8997874,6.49267411 C68.3922942,6.89773821 68.7874632,7.44311858 69.0858237,8.12881532 C69.3847137,8.814512 69.5344226,9.66858826 69.5344226,10.6889262 C69.5344226,11.0574551 69.5122042,11.4535177 69.4682968,11.8755256 C69.4243889,12.2975336 69.3587921,12.7370148 69.2709768,13.1950285 L61.3258026,13.1950285 C61.3787037,14.3032626 61.7257332,15.1382771 62.2425742,15.7011308 C62.7588858,16.2645141 63.5957768,16.5451467 64.7511305,16.5451467 C65.4684658,16.5451467 66.1022179,16.4053598 66.6820105,16.2158005 C67.4046358,15.9796454 68.0849405,15.6577122 68.0849405,15.6577122 L69.1122742,17.7857547 C68.5848532,18.2077626 67.8685758,18.5641131 66.9634432,18.8542766 Z"
                                        id="Fill-5"
                                        fill="#FF4C00"
                                    ></path>
                                    <path
                                        d="M80.6742774,8.27707405 C80.6742774,8.27707405 79.6252537,8.06368737 78.7989432,8.06368737 C76.4464437,8.06368737 74.9191968,9.41178295 74.9191968,12.3870183 C74.9191968,15.1779892 76.0840732,16.5567956 78.5407868,16.5567956 C79.3417053,16.5567956 80.8853516,16.3423499 80.8853516,16.3423499 L81.3577558,18.7266682 C80.7123658,18.932642 79.4448621,19.2439854 77.9197316,19.2439854 C73.1374974,19.2439854 71.3790742,15.7509034 71.3790742,12.2059308 C71.3790742,8.63554253 73.4469674,5.42679963 78.2043379,5.42679963 C79.0830205,5.42679963 80.1674874,5.53005126 81.1768347,5.78897458 L80.6742774,8.27707405"
                                        id="Fill-6"
                                        fill="#FF4C00"
                                    ></path>
                                    <path
                                        d="M86.0881468,9.48908932 C85.0132021,9.48908932 84.1429837,8.61806916 84.1429837,7.54266374 C84.1429837,6.46725832 85.0132021,5.59570868 86.0881468,5.59570868 C87.1615042,5.59570868 88.0327811,6.46725832 88.0327811,7.54266374 C88.0327811,8.61806916 87.1615042,9.48908932 86.0881468,9.48908932 Z"
                                        id="Fill-7"
                                        fill="#FF4C00"
                                    ></path>
                                    <path
                                        d="M88.0327811,17.1694219 C88.0327811,18.2442978 87.1615042,19.1158474 86.0881468,19.1158474 C85.0132021,19.1158474 84.1429837,18.2442978 84.1429837,17.1694219 C84.1429837,16.093487 85.0132021,15.2219374 86.0881468,15.2219374 C87.1615042,15.2219374 88.0327811,16.0940165 88.0327811,17.1694219"
                                        id="Fill-8"
                                        fill="#FF4C00"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                </PanelHeader>
                <Search
                    after={null}
                    onChange={e => {
                        loadFoundBooks([]);
                        setSearchCounter(10);
                        const value = e.target.value;

                        setSearchValue(e.target.value);

                        if (e.target.value.length === 0) {
                            loadFoundBooks([]);
                            return;
                        }
                        setLoading(true);
                        const timeout = setTimeout(() => {
                            searchHandler(value);
                            clearTimeout(timeout);
                        }, 300);
                    }}
                />
                {!searchValue ? (
                    <div className="shop__wrapper">
                        <Group className="shop__popular">
                            <Header
                                aside={
                                    <Link
                                        onClick={() => {
                                            VkRetargetEvent('micro');
                                            openShowAllPanel('Популярные', 'r_genre_arts_pop');
                                        }}
                                    >
                                        Показать все
                                    </Link>
                                }
                            >
                                Популярные
                            </Header>
                            <CardScroll>
                                {books.popularBooks ? (
                                    books.popularBooks
                                        .slice(0, 10)
                                        .map(book => (
                                            <SliderCell gaEvent={trackButBtnHandler} key={book.id} {...book} />
                                        ))
                                ) : (
                                    <Spinner />
                                )}
                            </CardScroll>
                        </Group>
                        <Group className="shop__latest">
                            <Header
                                aside={
                                    <Link
                                        onClick={() => {
                                            VkRetargetEvent('micro');
                                            openShowAllPanel('Последние', 'r_genre_arts_new');
                                        }}
                                    >
                                        Показать все
                                    </Link>
                                }
                            >
                                Последние
                            </Header>
                            <CardScroll>
                                {books.newBooks ? (
                                    books.newBooks
                                        .slice(0, 10)
                                        .map(book => (
                                            <SliderCell gaEvent={trackButBtnHandler} key={book.id} {...book} />
                                        ))
                                ) : (
                                    <Spinner />
                                )}
                            </CardScroll>
                        </Group>
                        <Group className="shop__editors-choice">
                            <Header
                                aside={
                                    <Link
                                        onClick={() => {
                                            VkRetargetEvent('micro');
                                            openShowAllPanel('Выбор редакции', 'editors-choice');
                                        }}
                                    >
                                        Показать все
                                    </Link>
                                }
                            >
                                Выбор редактора
                            </Header>
                            <CardScroll>
                                {books.chosenBooks ? (
                                    books.chosenBooks.map(book => (
                                        <SliderCell gaEvent={trackButBtnHandler} key={book.id} {...book} />
                                    ))
                                ) : (
                                    <Spinner />
                                )}
                            </CardScroll>
                        </Group>
                    </div>
                ) : (
                    <SearchResult>
                        {foundBooks.length > 0 && foundBooks.map(book => <BookCard key={book.id} {...book} />)}
                        {foundBooks.length === 0 && !loading && searchValue.length > 0 && (
                            <Placeholder
                                className="empty-search"
                                style={{ marginLeft: platform() === ANDROID ? 16 : 12 }}
                            >
                                Ничего не найдено
                            </Placeholder>
                        )}
                        {loading && <Spinner />}
                    </SearchResult>
                )}
            </div>
        </Panel>
    );
};

export default Shop;
