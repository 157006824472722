import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'react-router-redux';
import { Alert, ANDROID, List, Panel, PanelHeader, PanelHeaderBack, Search, Spinner, platform } from '@vkontakte/vkui';
import { setPopout } from '../../store/navigation/actions';
import BookCard from '../../components/BookCard';
import SearchResult from '../../components/SearchResult';
import VkRetargetEvent from '../../utils/VkRetargetEvent';
import GA from 'react-ga';
import * as API from '../../networking/api';

const ShowAllAuthor = ({ id, router }) => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [foundBooks, loadFoundBooks] = useState([]);
    const [filteredBooks, setFilteredBooks] = useState(null);
    const [loading, setLoading] = useState(false);
    const selectedBook = useSelector(state => state.shop.selectedBook);

    const searchHandler = useCallback(
        value => {
            setFilteredBooks(foundBooks.filter(book => book.title.toLowerCase().includes(value.toLowerCase())));
        },
        [foundBooks],
    );

    useEffect(() => {
        setLoading(true);
        API.searchBooks(selectedBook.persons[0].full_name, '999')
            .then(data => {
                if (data.result.success) {
                    setLoading(false);
                    loadFoundBooks(
                        data.result.arts.filter(art => art.persons[0].full_name === selectedBook.persons[0].full_name),
                    );
                }
            })
            .catch(() => {
                setLoading(false);
                dispatch(
                    setPopout(
                        <Alert
                            actions={[
                                {
                                    title: 'Закрыть',
                                    autoclose: true,
                                    mode: 'cancel',
                                },
                            ]}
                            onClose={() => dispatch(setPopout(null))}
                        >
                            <h2>Что-то пошло не так</h2>
                            <p>Не удалось загрузить книги</p>
                        </Alert>,
                    ),
                );
            });
    }, []);

    useEffect(() => {
        GA.pageview(`shop/show-all-author`);
    }, []);

    const trackBuyBtnHandler = () => {
        GA.event({
            category: 'User',
            action: 'click-buy-btn',
            label: `shop/author-books`,
        });

        VkRetargetEvent('click_pay');
    };

    return (
        <Panel id={id} separator={false}>
            <div className="show-all">
                <PanelHeader separator={false} left={<PanelHeaderBack onClick={() => dispatch(goBack())} />}>
                    Другие книги автора
                </PanelHeader>
                <Search
                    after={null}
                    value={searchValue}
                    onChange={e => {
                        setSearchValue(e.target.value);
                        searchHandler(e.target.value);
                    }}
                />
                {loading && <Spinner />}
                {!searchValue ? (
                    <List style={{ paddingTop: '8px' }}>
                        {foundBooks &&
                            foundBooks.map(book => <BookCard gaEvent={trackBuyBtnHandler} key={book.id} {...book} />)}
                    </List>
                ) : (
                    <SearchResult>
                        {filteredBooks &&
                            filteredBooks.length !== 0 &&
                            !loading &&
                            filteredBooks.map(book => <BookCard router={router} key={book.id} {...book} />)}
                        {filteredBooks.length === 0 && searchValue.length > 0 && (
                            <div style={{ marginLeft: platform() === ANDROID ? 16 : 12 }}>Такой книги не найдено</div>
                        )}
                    </SearchResult>
                )}
            </div>
        </Panel>
    );
};

export default ShowAllAuthor;
