import * as types from './actionsTypes';

const initialState = {
    books: [],
    categoryBooks: [],
    showAllPanelTitle: '',
    showAllContainerType: null,
    currentPaymentsPrice: null,
    paymentStatus: null,
    paymentError: null,
    currentBookId: null,
    currentOrderId: null,
    selectedBook: null,
    searchCounter: 0,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SHOP_LOAD_BOOKS:
            const { books } = action;
            return {
                ...state,
                books,
            };
        case types.SHOP_SET_CATEGORY_BOOKS:
            return {
                ...state,
                categoryBooks: [...state.categoryBooks, ...action.books],
            };
        case types.SHOP_RESET_CATEGORY_GROUP:
            return {
                ...state,
                categoryBooks: [],
            };
        case types.SHOP_SET_SELECTED_BOOK:
            const { selectedBook } = action;
            return {
                ...state,
                selectedBook,
            };
        case types.SHOP_SET_PAYMENTS_PRICE:
            const { currentPaymentsPrice } = action;
            return {
                ...state,
                currentPaymentsPrice,
            };
        case types.SHOP_SET_PAYMENTS_BOOK_ID:
            const { currentBookId } = action;
            return {
                ...state,
                currentBookId,
            };
        case types.SHOP_SET_PAYMENTS_ORDER_ID:
            const { currentOrderId } = action;
            return {
                ...state,
                currentOrderId,
            };
        case types.SHOP_SET_PAYMENT_STATUS:
            const { paymentStatus } = action;
            return {
                ...state,
                paymentStatus,
            };
        case types.SHOP_SET_PAYMENT_ERROR:
            const { paymentError } = action;
            return {
                ...state,
                paymentError,
            };
        case types.SHOP_SET_SHOW_ALL_TITLE:
            const { showAllPanelTitle } = action;
            return {
                ...state,
                showAllPanelTitle,
            };
        case types.SHOP_SET_SHOW_ALL_TYPE:
            const { containerType } = action;
            return {
                ...state,
                showAllContainerType: containerType,
            };
        case types.SET_SEARCH_COUNTER:
            const { searchCounter } = action;
            return {
                ...state,
                searchCounter,
            };
        default:
            return state;
    }
};

export default reducer;
