import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { Panel } from '@vkontakte/vkui';
import { getVKStorage } from '../../networking/api';
import bridge from '@vkontakte/vk-bridge';
import * as API from '../../networking/api';

const Empty = ({ id }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const isAllowToken = await getVKStorage(['allow_token']);
            if (isAllowToken['allow_token'] && isAllowToken['allow_token'] === '1') {
                bridge
                    .send('VKWebAppGetAuthToken', { app_id: 7371328, scope: '' })
                    .then(data => {
                        return API.getAuthUser(data.access_token);
                    })
                    .then(data => {
                        if (data.success) {
                            dispatch(push('shop'));
                        } else {
                            dispatch(push('welcome'));
                        }
                    })
                    .catch(() => {
                        dispatch(push('welcome'));
                    });
            } else {
                dispatch(push('welcome'));
            }
        })();
    }, []);

    return <Panel id={id} />;
};

export default Empty;
