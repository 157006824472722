import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import { ConfigProvider, Epic, Tabbar, TabbarItem, View, ModalRoot } from '@vkontakte/vkui';
import { setModal } from '../../store/navigation/actions';
import { sendStat } from '../../networking/api';
import Icon28MarketOutline from '@vkontakte/icons/dist/28/market_outline';
import Shop from '../Shop';
import ShowAllPanel from '../ShowAllPanel';
import BookDetails from '../BookDetails';
import PaymentsModal from '../PaymentsModal';
import PaymentsStatus from '../PaymentsStatus';
import Reader from '../Reader';
import BookContent from '../BookContent';
import ReaderSettings from '../ReaderSettings';
import BookStructure from '../BookStructure';
import Login from '../Login';
import SelectFont from '../SelectFont';
import Empty from '../Empty';
import ShowAllAuthor from '../ShowAllAuthor';
import GA from 'react-ga';
import book from '../../assets/book28.svg';
import bookOff from '../../assets/book28-off.svg';
import './index.scss';

const App = ({ pageId, history }) => {
    const dispatch = useDispatch();
    const activeModal = useSelector(store => store.navigation.activeModal);
    const activePopout = useSelector(store => store.navigation.activePopout);
    const [activePanel, setActivePanel] = useState('login');
    const [activeStory, setActiveStory] = useState('dummy');

    useEffect(() => {
        switch (pageId) {
            case '':
                return;
            case 'shop':
            case 'book-details':
            case 'show-all':
            case 'show-all-author':
                setActiveStory('shop');
                break;
            case 'reader':
            case 'book-content':
            case 'book-structure':
                setActiveStory('reader');
                break;
            case 'welcome':
                setActiveStory('welcome');
                break;
        }
        setActivePanel(pageId);
    }, [pageId]);

    const onCloseModal = () => {
        dispatch(setModal(null));
    };

    const modals = (
        <ModalRoot activeModal={activeModal} onClose={onCloseModal}>
            <PaymentsModal id="payments" />
            <PaymentsStatus id="payments-status" />
            <ReaderSettings id="reader-settings" />
            <SelectFont id="select-font" />
        </ModalRoot>
    );

    const openBack = () => {
        dispatch(goBack());
    };

    const tabHandler = tab => {
        dispatch(push(tab));
        GA.event({
            category: 'Navigation',
            action: 'click-on-tab',
            label: `tab/${tab}`,
        });
    };

    return (
        <div className="app">
            <ConfigProvider>
                <Epic
                    id="main"
                    activeStory={activeStory}
                    tabbar={
                        <Tabbar
                            className={
                                activeStory === 'welcome' || activeStory === 'dummy' || activePanel === 'book-content'
                                    ? 'hide'
                                    : 'show'
                            }
                            shadow
                        >
                            <TabbarItem
                                onClick={() => tabHandler('shop')}
                                selected={activeStory === 'shop'}
                                data-story="shop"
                                text="Магазин"
                            >
                                <Icon28MarketOutline />
                            </TabbarItem>
                            <TabbarItem
                                onClick={() => tabHandler('reader')}
                                selected={activeStory === 'reader'}
                                data-story="reader"
                                text="Мои книги"
                            >
                                <img src={activeStory === 'reader' ? book : bookOff} alt="" />
                            </TabbarItem>
                        </Tabbar>
                    }
                >
                    <View id="dummy" activePanel="empty">
                        <Empty id="empty" />
                    </View>
                    <View id="welcome" activePanel="login">
                        <Login id="login" />
                    </View>
                    <View
                        id="shop"
                        activePanel={activePanel}
                        onSwipeBack={openBack}
                        modal={modals}
                        history={history}
                        popout={activePopout}
                    >
                        <Shop id="shop" />
                        <ShowAllPanel id="show-all" />
                        <BookDetails id="book-details" />
                        <ShowAllAuthor id="show-all-author" />
                    </View>
                    <View
                        id="reader"
                        activePanel={activePanel}
                        onSwipeBack={openBack}
                        modal={modals}
                        history={history}
                        popout={activePopout}
                    >
                        <Reader id="reader" />
                        <BookContent id="book-content" />
                        <BookStructure id="book-structure" />
                    </View>
                </Epic>
            </ConfigProvider>
        </div>
    );
};

export default App;
