import React from 'react';
import propTypes from 'prop-types';
import './index.scss';

const GenreBlock = ({ text }) => {
    return <span className="hashtag">{text}</span>;
};

GenreBlock.propTypes = {
    text: propTypes.string.isRequired,
};

GenreBlock.defaultProps = {
    text: 'genre',
};

export default GenreBlock;
