export const SHOP_LOAD_BOOKS = '@shop/load-books';
export const SHOP_SET_SHOW_ALL_TITLE = '@shop/set-show-all-title';
export const SHOP_SET_SHOW_ALL_TYPE = '@shop/set-show-all-type';
export const SHOP_SET_PAYMENTS_PRICE = '@shop/set-payments-price';
export const SHOP_SET_PAYMENTS_BOOK_ID = '@shop/set-payments-book-id';
export const SHOP_SET_PAYMENTS_ORDER_ID = '@shop/set-payments-order-id';
export const SHOP_SET_SELECTED_BOOK = '@shop/set-selected-book';
export const SET_SEARCH_COUNTER = '@shop/set-search-counter';
export const SHOP_SET_CATEGORY_BOOKS = '@shop/shop-set-category-books';
export const SHOP_RESET_CATEGORY_GROUP = '@shop/shop-reset-category-books';
export const SHOP_SET_PAYMENT_STATUS = '@shop/set-payment-status';
export const SHOP_SET_PAYMENT_ERROR = '@shop/set-payment-error';
