import React, { useEffect } from 'react';
import { store } from '@mini-core/core';
import { Cell, ModalCard, ModalPageHeader } from '@vkontakte/vkui';
import './index.scss';

const SelectFont = ({ id, router }) => {
    const fonts = [
        'Arial',
        'Myriad Pro',
        'Verdana',
        'Helvetica',
        'Times New Roman',
        'Minion Pro',
        'Georgia',
        'PT Sans',
        'Calibri',
        'Cambria',
    ];
    const [settings, settingsActions] = store.useModel('reader-settings');
    const { activeFont } = settings;
    const { setFont } = settingsActions;

    useEffect(() => {
        window.frames[0].postMessage(
            JSON.stringify({ type: 'LitresWebAppSwitchFontFamily', fontFamily: activeFont }),
            '*',
        );
    }, [activeFont]);

    const selectFontHandler = index => {
        setFont(index);
    };

    return (
        <ModalCard id={id} header={<ModalPageHeader>Выбор Шрифта</ModalPageHeader>} onClose={() => router.goBack()}>
            {fonts.map((font, index) => (
                <Cell
                    className={index === activeFont && 'font_active'}
                    expandable
                    onClick={() => selectFontHandler(index)}
                >
                    {font}
                </Cell>
            ))}
        </ModalCard>
    );
};

export default SelectFont;
