import { network } from '@mini-core/core';
import bridge from '@vkontakte/vk-bridge';

export const getBooks = () => network.call('getCachedBooks');
export const getBooksTest = (method, count, offset) => network.call('getBooks', { method, count, offset });
export const getAuthUser = token => network.call('authorize', { token });
export const searchBooks = (q, limitMax = 5) => network.call('search', { q, limitMax });
export const getUserBooks = () => network.call('getMyBooks');
export const getReaderUrl = id => network.call('getReaderUrl', { id });
export const getPaymentInfo = id => network.call('getPaymentInfo', { id });
export const buyBook = (bookID, orderID) => network.call('buyBook', { bookID, orderID });
export const sendStat = events => network.call('sendStat', { eve: events });

export const getVKStorage = keys =>
    new Promise(resolve => {
        bridge
            .send('VKWebAppStorageGet', {
                keys,
            })
            .then(response => {
                const compiled = response.keys.reduce((a, x) => {
                    a[x.key] = x.value;
                    return a;
                }, {});
                resolve(compiled);
            })
            .catch(error => {
                resolve({});
            });
    });

export const setVKStorage = (key, value) =>
    new Promise(resolve => {
        bridge
            .send('VKWebAppStorageSet', {
                key,
                value,
            })
            .then(response => resolve())
            .catch(error => resolve());
    });

export const openFullScreenPicture = (images, start_index) => {
    bridge.send('VKWebAppShowImages', {
        images,
        start_index,
    });
};
