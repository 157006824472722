import * as types from './actionTypes';

export const setModal = activeModal => {
    return {
        type: types.NAVIGATION_SET_MODAL,
        activeModal,
    };
};

export const setPopout = activePopout => {
    return {
        type: types.NAVIGATION_SET_POPOUT,
        activePopout,
    };
};

export const setStory = activeStory => {
    return {
        type: types.NAVIGATION_SET_STORY,
        activeStory,
    };
};

export const setSearchValue = searchValue => {
    return {
        type: types.NAVIGATION_SET_SEARCH_VALUE,
        searchValue,
    };
};
