import 'core-js/features/map';
import 'core-js/features/set';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import bridge from '@vkontakte/vk-bridge';
import { utils, network } from '@mini-core/core';

import App from './containers/App';
import '@vkontakte/vkui/dist/vkui.css';
import './store/navigation/index';
import './store/shop/index';
import './store/reader/index';
import './store/reader-settings';
import { createHashHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import rootReducer from './store';
import { composeWithDevTools } from 'redux-devtools-extension';

const history = createHashHistory({
    hashType: 'noslash',
});

const logger = store => next => action => {
    if (process.env.NODE_ENV !== 'production') console.log('dispatching', action);
    return next(action);
};

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history), logger)),
);

window.vk_settings = utils.parseParams(window.location.search);
network.init('https://back.litres.juice.vk-apps.com/', 'https://back.litres.juice.vk-apps.com/');

bridge.subscribe(utils.schemeChanger);
bridge.send('VKWebAppInit');

ReactGA.initialize('UA-171739682-1', {
    titleCase: false,
    gaOptions: {
        userId: window.vk_settings.vk_user_id || 0,
    },
});

const urls = [
    'welcome',
    'shop',
    'book-details',
    'show-all',
    'show-all-author',
    'reader',
    'book-content',
    'book-structure',
];

const pathRegex = `/:pageId(${urls.join('|')}|)?`;

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Route path={pathRegex} component={props => <App history={history} pageId={props.match.params.pageId} />} />
        </Router>
    </Provider>,
    document.getElementById('root'),
);
