import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'react-router-redux';
import { utils } from '@mini-core/core';
import {
    Panel,
    Button,
    Div,
    Group,
    Header,
    Link,
    CardScroll,
    Separator,
    Spinner,
    Alert,
    PanelHeaderBack,
    IOS,
    platform,
} from '@vkontakte/vkui';
import { setCurrentBookReader } from '../../store/reader/actions';
import { setPaymentsBookId, setPaymentsPrice, setShowAllTitle, setShowAllType } from '../../store/shop/actions';
import { setModal, setPopout } from '../../store/navigation/actions';
import BookCard from '../../components/BookCard';
import GenreBlock from '../../components/GenreBlock';
import SliderCell from '../../components/SliderCell';
import ParsePrice from '../../utils/ParsePrice';
import FireEvent from '../../utils/FireEvent';
import VkRetargetEvent from '../../utils/VkRetargetEvent';
import GA from 'react-ga';
import * as API from '../../networking/api';
import './index.scss';

const BookDetails = ({ id }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [authorBooks, setAuthorBooks] = useState([]);
    const [fullAnno, setFullAnno] = useState(false);
    const selectedBook = useSelector(state => state.shop.selectedBook);
    const [countGenres, setCountGenres] = useState(selectedBook.genres.length < 4 ? selectedBook.genres.length : 4);
    const {
        ll_avg_marks,
        base_price: BP,
        inapp_price: IP,
        annotation,
        final_price: FP,
        title,
        chars,
        persons,
        minage,
        my,
        year_written,
        genres,
    } = selectedBook;

    let base_price = BP;
    let final_price = FP;

    if (platform() === IOS) {
        base_price = IP;
        final_price = IP;
    }

    const onBuyButtonClickHandler = () => {
        GA.event({
            category: 'User',
            action: 'click-buy-btn',
            label: 'shop/book-details-header-btn',
        });

        VkRetargetEvent('click_pay');

        if (platform() === IOS) {
            FireEvent(
                `https://litres.onelink.me/1566260005/?pid=vkminiapp_DL&af_dp=litresread%3A%2F%2Fcontent%2Fb%2F${selectedBook.id}%2Fref%2F805522491&af_force_dp=true`,
            );
            return;
        }

        dispatch(
            setPaymentsPrice(
                Number(base_price) !== Number(final_price) ? ParsePrice(final_price) : ParsePrice(base_price),
            ),
        );
        dispatch(setPaymentsBookId(selectedBook.id));
        dispatch(setModal('payments'));
    };

    const openShowAllPanel = (containerTitle, containerType) => {
        dispatch(setShowAllTitle(containerTitle));
        dispatch(setShowAllType(containerType));
        dispatch(push('show-all-author'));
    };

    const onReadButtonClickHandler = () => {
        dispatch(setCurrentBookReader(selectedBook.id));
        dispatch(push('book-content'));
    };

    useEffect(() => {
        GA.ga('send', 'pageview', '/shop/book-details');
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500);
        setFullAnno(false);
        return () => clearTimeout(timeout);
    }, [selectedBook]);

    useEffect(() => {
        if (persons && persons[0].full_name) {
            setLoading(true);
            API.searchBooks(persons[0].full_name)
                .then(data => {
                    if (data.result.success) {
                        setLoading(false);
                        setAuthorBooks(
                            data.result.arts.filter(art => art.persons[0].full_name === persons[0].full_name),
                        );
                    }
                })
                .catch(() => {
                    setLoading(false);
                    dispatch(
                        setPopout(
                            <Alert
                                actions={[
                                    {
                                        title: 'Закрыть',
                                        autoclose: true,
                                        mode: 'cancel',
                                    },
                                ]}
                                onClose={() => dispatch(setPopout(null))}
                            >
                                <h2>Что-то пошло не так</h2>
                                <p>Не удалось загрузить книги</p>
                            </Alert>,
                        ),
                    );
                });
        }
    }, []);

    const trackButBtnHandler = () => {
        GA.event({
            category: 'User',
            action: 'click-buy-btn',
            label: 'shop/book-details',
        });

        VkRetargetEvent('click_pay');
    };

    const mapAnnotation = () => {
        return annotation
            .split('</p>')
            .map((p, i) => p.trim().slice(3, p.length))
            .join(' ');
    };

    return (
        <Panel id={id} separator={false}>
            <div className="book-details">
                <div className="book-details__bg">
                    <img
                        className="book-details__bg-picture"
                        src={`https://cv${selectedBook.id[selectedBook.id.length - 2]}.litres.ru/pub/c/cover/${
                            selectedBook.id
                        }.jpg`}
                        alt=""
                    />
                    <div className="book-details__bg--blur" />
                    <PanelHeaderBack onClick={() => dispatch(goBack())} />
                    <div className="book-details__header">
                        <BookCard
                            id={selectedBook.id}
                            title={title}
                            ll_avg_marks={ll_avg_marks}
                            persons={persons}
                            base_price={base_price}
                            final_price={final_price}
                            inapp_price={IP}
                            showButton={false}
                        />
                        {my && Number(my) === 1 ? (
                            <Button
                                className="book-details__header-btn"
                                stretched
                                mode="commerce"
                                size="xl"
                                onClick={onReadButtonClickHandler}
                            >
                                Читать
                            </Button>
                        ) : (
                            <Button
                                className="book-details__header-btn"
                                stretched
                                size="xl"
                                mode="commerce"
                                onClick={onBuyButtonClickHandler}
                            >
                                Купить за{' '}
                                {Number(base_price) !== Number(final_price)
                                    ? ParsePrice(final_price)
                                    : ParsePrice(base_price)}{' '}
                                ₽
                                {Number(base_price) !== Number(final_price) ? (
                                    <div className="book-details__header-btn--crossed">{ParsePrice(base_price)} ₽</div>
                                ) : (
                                    ''
                                )}
                            </Button>
                        )}
                    </div>
                </div>
                <Div>
                    {annotation &&
                        (!fullAnno ? (
                            <Group>
                                <div className="book-details__desc">
                                    <h4 className="book-details__title">О книге</h4>
                                    <p className="book-details__summary">
                                        {`${mapAnnotation().slice(0, 100)}...`}
                                        <span
                                            className="book-details__desc-show-more"
                                            onClick={() => setFullAnno(true)}
                                        >
                                            Показать полностью...
                                        </span>
                                    </p>
                                </div>
                            </Group>
                        ) : (
                            <Group>
                                <div className="book-details__desc">
                                    <h4 className="book-details__title">О книге</h4>
                                    <p
                                        className="book-details__summary"
                                        dangerouslySetInnerHTML={{ __html: annotation }}
                                    />
                                </div>
                            </Group>
                        ))}
                    <Group>
                        <div className="book-details__caption">
                            <span>Возрастное ограничение: {minage && minage}+</span>
                            {year_written && <span>Дата написания: {year_written}</span>}
                            <span>
                                Объем: {chars && Math.floor(chars / 1595)}{' '}
                                {utils.wordPad(Math.floor(chars / 1595), 'страница', 'страницы', 'страниц')}
                            </span>
                        </div>
                    </Group>
                    <Group>
                        <div
                            className={
                                countGenres !== genres.length
                                    ? 'book-details__tags'
                                    : 'book-details__tags book-details__tags--expanded'
                            }
                        >
                            {genres &&
                                genres
                                    .slice(0, countGenres)
                                    .map(genre => <GenreBlock key={genre.id} text={genre.name} />)}
                            {genres.length > 4 && countGenres !== genres.length && (
                                <span className="book-details__show-more" onClick={() => setCountGenres(genres.length)}>
                                    <GenreBlock text="показать всё" />
                                </span>
                            )}
                        </div>
                    </Group>
                </Div>
                <Separator />
                {!loading && authorBooks.length > 1 && (
                    <Group>
                        <Header
                            aside={
                                <Link onClick={() => openShowAllPanel('Другие книги автора', 'other-books-author')}>
                                    Показать все
                                </Link>
                            }
                        >
                            Другие книги автора
                        </Header>
                        <CardScroll>
                            {!loading &&
                                authorBooks.length > 0 &&
                                authorBooks
                                    .filter(book => book.id !== selectedBook.id)
                                    .map(book => <SliderCell gaEvent={trackButBtnHandler} key={book.id} {...book} />)}
                        </CardScroll>
                    </Group>
                )}
                {loading && <Spinner style={{ marginTop: 12 }} />}
            </div>
        </Panel>
    );
};

BookDetails.defaultProps = {
    isBought: true,
};

export default BookDetails;
