import React from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { ModalCard } from '@vkontakte/vkui';
import { setModal } from '../../store/navigation/actions';
import { setPaymentStatus, setPaymentError } from '../../store/shop/actions';
import Icon28CancelCircleOutline from '@vkontakte/icons/dist/28/cancel_circle_outline';
import Icon16CheckCircleOutline from '@vkontakte/icons/dist/16/check_circle_outline';
import './index.scss';

const PaymentsStatus = ({ id }) => {
    const dispatch = useDispatch();
    const paymentStatus = useSelector(state => state.shop.paymentStatus);
    const paymentError = useSelector(state => state.shop.paymentError);

    const onClose = () => {
        dispatch(setModal(null));
        dispatch(setPaymentStatus(null));
        dispatch(setPaymentError(null));
    };

    const openReader = () => {
        onClose();
        dispatch(push('reader'));
    };

    return (
        <div
            className={
                paymentStatus ? 'payments-status payments-status--success' : 'payments-status payments-status--error'
            }
        >
            <ModalCard
                id={id}
                onClose={onClose}
                icon={
                    paymentStatus ? (
                        <Icon16CheckCircleOutline width={70} height={70} fill="#0e920e" />
                    ) : (
                        <Icon28CancelCircleOutline width={70} height={70} fill="#eb4250" />
                    )
                }
                header={paymentStatus ? 'Оплачено' : 'Ошибка'}
                caption={paymentStatus ? '' : 'Оплата не прошла'}
                actions={
                    paymentStatus
                        ? [
                              {
                                  title: 'Читать',
                                  mode: 'success',
                                  action: openReader,
                              },
                          ]
                        : [
                              {
                                  title: 'Повторить',
                                  mode: 'secondary',
                                  action: () => dispatch(setModal('payments')),
                              },
                          ]
                }
            ></ModalCard>
        </div>
    );
};

PaymentsStatus.propTypes = {
    id: propTypes.string.isRequired,
};

export default PaymentsStatus;
