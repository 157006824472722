import * as types from './actionTypes';

const initialState = {
    activeStory: 'shop',
    activePanel: 'shop',
    activeModal: null,
    activePopout: null,
    searchValue: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.NAVIGATION_SET_MODAL:
            const { activeModal } = action;
            return {
                ...state,
                activeModal,
            };
        case types.NAVIGATION_SET_POPOUT:
            const { activePopout } = action;
            return {
                ...state,
                activePopout,
            };
        default:
            return state;
    }
};

export default reducer;
