import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ModalCard, FormLayout, Radio, Separator } from '@vkontakte/vkui';
import { setModal } from '../../store/navigation/actions';
import { setPaymentStatus, setPaymentError } from '../../store/shop/actions';
import VkRetargetEvent from '../../utils/VkRetargetEvent';
import bridge from '@vkontakte/vk-bridge';
import GA from 'react-ga';
import * as API from '../../networking/api';
import './index.scss';
import { sendStat } from '../../networking/api';

const PaymentsModal = ({ id }) => {
    const dispatch = useDispatch();
    const currentPaymentsPrice = useSelector(state => state.shop.currentPaymentsPrice);
    const currentBookID = useSelector(state => state.shop.currentBookId);
    const [loading, setLoading] = useState(null);

    const openVkPay = async () => {
        GA.event({
            category: 'User',
            action: 'click-buy-btn',
            label: 'payment-modal',
        });

        VkRetargetEvent('click_pay');

        setLoading(true);

        const paymentInfo = await API.getPaymentInfo(currentBookID);

        bridge
            .send('VKWebAppOpenPayForm', {
                app_id: 7371328,
                action: 'pay-to-service',
                params: {
                    amount: paymentInfo.paymentInfo.amount,
                    data: { ...paymentInfo.paymentInfo.data },
                    description: paymentInfo.paymentInfo.description,
                    merchant_id: paymentInfo.paymentInfo.merchant_id,
                    sign: paymentInfo.paymentInfo.sign,
                },
            })
            .then(data => {
                if (data.status || data.result.status) {
                    GA.event({
                        category: 'User',
                        action: 'click-buy-btn',
                        label: 'payment-modal',
                    });

                    VkRetargetEvent('purchase');

                    const events = JSON.stringify([
                        {
                            json: JSON.stringify({ book_id: currentBookID }),
                            event: 'book_purchase',
                            user_id: Number(window.vk_settings.vk_user_id),
                            mini_app_id: Number(window.vk_settings.vk_app_id),
                            url: window.location.href,
                            vk_platform: window.vk_settings.vk_platform,
                            type_click: {
                                type: 'type_mini_app_custom_event_item',
                            },
                        },
                    ]);

                    try {
                        sendStat(events);
                    } catch (e) {
                        console.log(e);
                    }

                    return API.buyBook(currentBookID, paymentInfo.litresOrderID);
                }
            })
            .then(async data => {
                if (data.success) {
                    setLoading(false);
                    dispatch(setPaymentStatus(true));
                    dispatch(setModal('payments-status'));
                }
            })
            .catch(e => {
                setLoading(false);
                dispatch(setPaymentStatus(false));
                dispatch(setPaymentError(e));
                dispatch(setModal('payments-status'));
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        GA.modalview('/payment-modal');
    }, []);

    return (
        <div className="payments-modal">
            <ModalCard
                id={id}
                onClose={() => dispatch(setModal(null))}
                header={<>Способ оплаты</>}
                actions={[
                    {
                        title: `Оплатить ${currentPaymentsPrice} ₽`,
                        mode: 'commerce',
                        action: openVkPay,
                    },
                ]}
            >
                <Separator wide />
                <FormLayout>
                    <div>
                        <Radio name="radio" value="1" defaultChecked>
                            VK Pay
                        </Radio>
                    </div>
                </FormLayout>
            </ModalCard>
        </div>
    );
};

PaymentsModal.propTypes = {
    id: propTypes.string.isRequired,
};

export default PaymentsModal;
