import * as types from './actionsTypes';

export const loadBooks = (books = []) => {
    return {
        type: types.SHOP_LOAD_BOOKS,
        books,
    };
};

export const setShowAllTitle = (showAllPanelTitle = '') => {
    return {
        type: types.SHOP_SET_SHOW_ALL_TITLE,
        showAllPanelTitle,
    };
};

export const setShowAllType = containerType => {
    return {
        type: types.SHOP_SET_SHOW_ALL_TYPE,
        containerType,
    };
};

export const setPaymentsPrice = (currentPaymentsPrice = null) => {
    return {
        type: types.SHOP_SET_PAYMENTS_PRICE,
        currentPaymentsPrice,
    };
};

export const setPaymentsBookId = currentBookId => {
    return {
        type: types.SHOP_SET_PAYMENTS_BOOK_ID,
        currentBookId,
    };
};

export const setPaymentsOrderId = currentOrderId => {
    return {
        type: types.SHOP_SET_PAYMENTS_ORDER_ID,
        currentOrderId,
    };
};

export const setSelectedBook = (selectedBook = null) => {
    return {
        type: types.SHOP_SET_SELECTED_BOOK,
        selectedBook,
    };
};

export const setSearchCounter = searchCounter => {
    return {
        type: types.SET_SEARCH_COUNTER,
        searchCounter,
    };
};

export const setPaymentStatus = paymentStatus => {
    return {
        type: types.SHOP_SET_PAYMENT_STATUS,
        paymentStatus,
    };
};

export const setPaymentError = paymentError => {
    return {
        type: types.SHOP_SET_PAYMENT_ERROR,
        paymentError,
    };
};

export const setCategoryBooks = books => {
    return {
        type: types.SHOP_SET_CATEGORY_BOOKS,
        books,
    };
};

export const resetCategoryBooks = () => {
    return {
        type: types.SHOP_RESET_CATEGORY_GROUP,
    };
};
