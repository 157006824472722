import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { Cell, Avatar, Button, ActionSheet, ActionSheetItem, platform, IOS } from '@vkontakte/vkui';
import { setPaymentsBookId, setPaymentsPrice, setSelectedBook } from '../../store/shop/actions';
import { setModal, setPopout } from '../../store/navigation/actions';
import { calculateDiscount } from '../../utils/CalculateDiscount';
import { setCurrentBookReader } from '../../store/reader/actions';
import ParsePrice from '../../utils/ParsePrice';
import FireEvent from '../../utils/FireEvent';
import * as API from '../../networking/api';
import './index.scss';

const SliderCell = props => {
    const dispatch = useDispatch();
    const { id, base_price: BP, final_price: FP, inapp_price: IP, type, title, my, persons, gaEvent } = props;

    let base_price = BP;
    let final_price = FP;

    if (platform() === IOS) {
        base_price = IP;
        final_price = IP;
    }

    const onBuyButtonClickHandler = () => {
        if (platform() === IOS) {
            FireEvent(
                `https://litres.onelink.me/1566260005/?pid=vkminiapp_DL&af_dp=litresread%3A%2F%2Fcontent%2Fb%2F${id}%2Fref%2F805522491&af_force_dp=true`,
            );
            return;
        }

        gaEvent && gaEvent();
        dispatch(
            setPaymentsPrice(
                Number(base_price) !== Number(final_price) ? ParsePrice(final_price) : ParsePrice(base_price),
            ),
        );
        dispatch(setPaymentsBookId(id));
        dispatch(setModal('payments'));
    };

    const openBookDetails = () => {
        dispatch(setSelectedBook({ ...props }));
        dispatch(push('book-details'));
    };

    const onReadButtonClickHandler = async e => {
        e.stopPropagation();

        if (Number(type) === 4) {
            try {
                const result = await API.getReaderUrl(id);
                dispatch(
                    setPopout(
                        <ActionSheet onClose={() => dispatch(setPopout(null))}>
                            {result.urls.map((head, pos) => {
                                return (
                                    <ActionSheetItem
                                        autoclose
                                        onClick={() => FireEvent(`https://back.litres.juice.vk-apps.com${head}`)}
                                    >
                                        Часть {pos + 1}
                                    </ActionSheetItem>
                                );
                            })}
                            {platform() === IOS && (
                                <ActionSheetItem autoclose mode="cancel">
                                    Отменить
                                </ActionSheetItem>
                            )}
                        </ActionSheet>,
                    ),
                );
            } catch (e) {
                throw new Error('Ошибка'); // TODO обработка ошибки
            }
        } else {
            dispatch(setCurrentBookReader(id));
            dispatch(push('book-content'));
        }
    };

    return (
        <div
            className={
                Number(base_price) !== Number(final_price) ? 'slider-cell slider-cell--discounted' : 'slider-cell'
            }
        >
            <Cell
                before={
                    <div>
                        <Avatar
                            src={`https://cv${id[id.length - 2]}.litres.ru/pub/c/cover/${id}.jpg`}
                            mode="image"
                            onClick={openBookDetails}
                        />
                        {Number(base_price) !== Number(final_price) ? (
                            <div className="Cell--discounted">-{calculateDiscount(base_price, final_price)}%</div>
                        ) : (
                            <></>
                        )}
                    </div>
                }
                size="l"
                description={persons[0].full_name}
                bottomContent={
                    <>
                        {!my && (
                            <Button
                                className={
                                    Number(base_price) !== Number(final_price)
                                        ? 'btn-mixin btn-mixin--orange'
                                        : 'btn-mixin'
                                }
                                stretched
                                size="m"
                                onClick={onBuyButtonClickHandler}
                            >
                                {Number(base_price) !== Number(final_price) ? (
                                    <span>
                                        <span className="full-price">{ParsePrice(Number(base_price))} ₽</span>
                                        <span className="discount-price">
                                            {Number(base_price) !== Number(final_price) &&
                                                ParsePrice(Number(final_price))}{' '}
                                            ₽
                                        </span>
                                    </span>
                                ) : (
                                    <span>{ParsePrice(Number(base_price))} ₽</span>
                                )}
                            </Button>
                        )}
                        {my && Number(my) === 1 && (
                            <Button className="btn-mixin" stretched size="m" onClick={onReadButtonClickHandler}>
                                Читать
                            </Button>
                        )}
                    </>
                }
            >
                {title}
            </Cell>
        </div>
    );
};

SliderCell.propTypes = {
    title: propTypes.string.isRequired,
    persons: propTypes.array.isRequired,
    base_price: propTypes.string.isRequired,
    final_price: propTypes.string.isRequired,
    cover: propTypes.string.isRequired,
};

export default SliderCell;
