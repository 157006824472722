import React from 'react';
import { Div } from '@vkontakte/vkui';
import './index.scss';

const SearchResult = props => {
    return (
        <div className="search-results">
            <Div>
                <span className="search-results__title">Результаты поиска</span>
            </Div>
            <div>{props.children}</div>
        </div>
    );
};

export default SearchResult;
