import { combineReducers } from 'redux';
import navigation from './navigation';
import shop from './shop';
import reader from './reader';

export default combineReducers({
    navigation,
    shop,
    reader,
});
