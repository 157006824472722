import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'react-router-redux';
import {
    List,
    Panel,
    PanelHeader,
    Search,
    PanelHeaderBack,
    Spinner,
    ANDROID,
    Placeholder,
    Alert,
    platform,
} from '@vkontakte/vkui';
import { debounce } from 'lodash';
import { resetCategoryBooks, setCategoryBooks, setShowAllType } from '../../store/shop/actions';
import { setPopout } from '../../store/navigation/actions';
import BookCard from '../../components/BookCard';
import SearchResult from '../../components/SearchResult';
import VkRetargetEvent from '../../utils/VkRetargetEvent';
import GA from 'react-ga';
import * as API from '../../networking/api';
import './index.scss';

const ShowAllPanel = ({ id, router }) => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [searchCounter, setSearchCounter] = useState(60);
    const [foundBooks, setFoundBooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const showAllPanelTitle = useSelector(state => state.shop.showAllPanelTitle);
    const showAllContainerType = useSelector(state => state.shop.showAllContainerType);
    const categoryBooks = useSelector(state => state.shop.categoryBooks);
    const books = useSelector(state => state.shop.books);

    const searchHandler = useCallback(
        debounce(value => {
            setLoading(false);
            setFoundBooks(
                categoryBooks.filter(
                    book =>
                        book.title.toLowerCase().includes(value.toLowerCase()) ||
                        (book.persons && book.persons[0].full_name.toLowerCase().includes(value.toLowerCase())),
                ),
            );
        }, 2000),
        [categoryBooks],
    );

    const trackButBtnHandler = () => {
        GA.event({
            category: 'User',
            action: 'click-buy-btn',
            label: `shop/${showAllContainerType}`,
        });

        VkRetargetEvent('click_pay');
    };

    const infiniteScrollLoader = () => {
        const getDocHeight = () => {
            const D = document;
            return Math.max(
                D.body.scrollHeight,
                D.documentElement.scrollHeight,
                D.body.offsetHeight,
                D.documentElement.offsetHeight,
                D.body.clientHeight,
                D.documentElement.clientHeight,
            );
        };

        const windowHeight = window.innerHeight || (document.documentElement || document.body).clientHeight;
        const docHeight = getDocHeight();
        const trackLength = docHeight - windowHeight;
        const scrollTop =
            window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

        if (trackLength - 150 <= scrollTop && !loading) {
            setLoading(true);
            API.getBooksTest(showAllContainerType, 60, searchCounter + 60)
                .then(data => {
                    setSearchCounter(prev => prev + 60);
                    dispatch(setCategoryBooks(data.books));
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                    dispatch(
                        setPopout(
                            <Alert
                                actions={[
                                    {
                                        title: 'Закрыть',
                                        autoclose: true,
                                        mode: 'cancel',
                                    },
                                ]}
                                onClose={() => dispatch(setPopout(null))}
                            >
                                <h2>Что-то пошло не так</h2>
                                <p>Не удалось загрузить книги</p>
                            </Alert>,
                        ),
                    );
                });
        }
    };

    useEffect(() => {
        showAllContainerType && GA.pageview(`shop/${showAllContainerType}`);
    }, []);

    useEffect(() => {
        if (showAllContainerType) {
            if (showAllContainerType !== 'r_genre_arts_pop' && showAllContainerType !== 'r_genre_arts_new') {
                dispatch(setCategoryBooks(books['chosenBooks']));
                setLoading(false);
                return;
            }

            setLoading(true);

            API.getBooksTest(showAllContainerType).then(d => {
                dispatch(setCategoryBooks(d.books));
                setLoading(false);
            });
        }
    }, [showAllContainerType]);

    useEffect(() => {
        if (
            showAllContainerType &&
            (showAllContainerType === 'r_genre_arts_pop' || showAllContainerType === 'r_genre_arts_new')
        ) {
            window.addEventListener('scroll', infiniteScrollLoader);
        }

        return () => {
            window.removeEventListener('scroll', infiniteScrollLoader);
        };
    }, [searchCounter, loading]);

    useEffect(() => {
        return () => {
            dispatch(resetCategoryBooks());
            dispatch(setShowAllType(null));
        };
    }, []);

    return (
        <Panel id={id} separator={false}>
            <div className="show-all">
                <PanelHeader separator={false} left={<PanelHeaderBack onClick={() => dispatch(goBack())} />}>
                    {showAllPanelTitle}
                </PanelHeader>
                <Search
                    after={null}
                    value={searchValue}
                    onChange={e => {
                        setLoading(true);
                        setFoundBooks([]);
                        setSearchValue(e.target.value);
                        searchHandler(e.target.value);
                    }}
                />
                {!searchValue ? (
                    <List style={{ paddingTop: '8px' }}>
                        {categoryBooks &&
                            categoryBooks.map(book => (
                                <BookCard gaEvent={trackButBtnHandler} key={book.id} {...book} />
                            ))}
                    </List>
                ) : (
                    <SearchResult>
                        {loading && <Spinner />}
                        {showAllPanelTitle !== '' && categoryBooks && foundBooks.length > 0
                            ? foundBooks.map(book => <BookCard gaEvent={trackButBtnHandler} key={book.id} {...book} />)
                            : !loading && (
                                  <Placeholder
                                      stretched
                                      className="empty-search"
                                      style={{ marginLeft: platform() === ANDROID ? 16 : 12 }}
                                  >
                                      Ничего не найдено
                                  </Placeholder>
                              )}
                    </SearchResult>
                )}
            </div>
            {!searchValue && loading && <Spinner />}
        </Panel>
    );
};

ShowAllPanel.propTypes = {
    id: propTypes.string.isRequired,
};

export default ShowAllPanel;
